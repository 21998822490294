$primary-text: #ffbc91;
$secondary-text: #fffaf8;
.section-hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: end;
  height: 90vh;
  &::before {
    content: "";
    background: url("../../asset/altea\ hero.jpg");
    background-size: cover; /* Mengatur agar gambar mengisi seluruh area */
    /* Menempatkan gambar di tengah */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; /* Meletakkan di belakang elemen lain */
    /* Overlay hitam */
    @media (max-width: 768px) {
      background-position: center;
    }
  }
  @media (max-width: 768px) {
    height: 70vh;
  }
  .container-hero {
    display: none;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    height: fit-content;
    width: 80%;
    text-align: start;
    padding: 40px 0;
    @media (max-width: 768px) {
      height: fit-content;
      width: 90%;
      padding: 20px 0;
      align-items: center;
    }
    .hero-description {
      display: flex;
      flex-direction: column;
      gap: 10px 0;
      background-color: #101418da;
      padding: 25px;
      border-radius: 25px;
      width: 50%;
      @media (max-width: 768px) {
        width: 100%;
        padding: 15px;
      }
      .hero-title {
        color: $primary-text;
        font-size: 3rem;
        font-family: "Montserrat";
        font-weight: 500;
        margin: 0;
        @media (max-width: 768px) {
          font-size: 2rem;
        }
      }
      .hero-text {
        color: $secondary-text;
        font-size: 1.2rem;
        font-family: "Poppins";
        font-weight: 400;
        line-height: 1.6rem;
        margin: 0;
        @media (max-width: 768px) {
          font-size: 0.9rem;
          line-height: 1.2rem;
        }
      }
    }
  }
}
