$primary-text-dark: #fff8ee;
$secondary-text-dark: #fffaf6;
$sub-link-surface: #fff8ee;
.section-footer {
  margin-top: 65px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #36301f;
  text-align: center;
  @media (max-width: 768px) {
    margin-top: 16px;
  }
  .container-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 25px;

    .footer-top {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      margin-top: 25px;
      width: 80%;
      gap: 32px;
      @media (max-width: 768px) {
        justify-content: center;
        width: 90%;
      }

      .footer-logo {
        text-align: start;
        width: calc(20% - 10px);
        @media (max-width: 768px) {
          width: 100%;
          text-align: center;
        }
        img {
          width: 248px;
          height: 105px;
        }
        .footer-logo-title {
          margin: 0;
          margin-top: 15px;
          font-family: "Poppins";
          color: $primary-text-dark;
          font-weight: 500;
          font-size: 1.3rem;
        }
        .footer-logo-text {
          color: $secondary-text-dark;
          font-family: "Roboto";
          font-size: 1rem;
          font-weight: 400;
          margin-top: 6px;
          text-decoration: none;
        }
      }
      .footer-sitemap {
        display: flex;
        margin: 0 0 0 4.5rem;
        width: 70%;
        @media (max-width: 768px) {
          width: 100%;
          margin: 0;
        }
        .footer-site-link {
          display: flex;
          text-align: start;
          gap: 40px;
          justify-content: end;
          width: 100%;
          flex-wrap: wrap;

          @media (max-width: 768px) {
            gap: 40px;
            justify-content: center;
            text-align: center;
          }
          .footer-menu {
            display: flex;
            width: calc(25% - 40px);
            list-style: none;
            flex-direction: column;
            font-family: "Roboto";
            gap: 9px;
            font-size: 1.2rem;
            color: $sub-link-surface;
            @media (max-width: 768px) {
              width: calc(50% - 16px);
            }
            .nav-item {
              .nav-links {
                text-decoration: none;
                color: $secondary-text-dark;
                font-size: 1rem;
                font-weight: 400;
                position: relative;
                font-weight: 500;
                &::before {
                  content: "";
                  position: absolute;
                  bottom: -6px;
                  left: 0;
                  width: 0%;
                  height: 2px;
                  background-color: $secondary-text-dark;
                  transition: all 0.3s ease-in-out;
                }
                &:hover::before {
                  width: 100%;
                }
              }
            }
          }
          .footer-map {
            display: flex;
            width: calc(50% - 40px);
            flex-direction: column;
            font-family: "Roboto";
            gap: 9px;
            font-size: 1.2rem;
            color: $sub-link-surface;
            @media (max-width: 768px) {
              width: 100%;
            }
            .footer-map-img {
              height: 161px;
              border-radius: 9px;
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
    .footer-rights {
      width: 100%;
      padding: 15px 0;
      font-family: "Roboto";
      font-size: 0.9rem;
      text-align: center;
      background-color: #7d745f;
      color: $secondary-text-dark;
      .footer-powered {
        font-size: 8px;
      }
    }
  }
}
