$text-primary: #4a4230;
$text-secondary: #1d1b1a;
$btn-primary: #094a51;
$btn-secondary: #ffffff;
.section-about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: start;
  .container {
    display: flex;
    width: 70%;
    padding: 40px 0;
    gap: 40px 20px;
    flex-wrap: wrap;
    @media (max-width: 768px) {
      flex-direction: column;
      width: 90%;
      padding: 16px 0;
      align-items: center;
      gap: 20px;
    }
    .container-title {
      width: calc(100% / 2 - 10px);
      @media (max-width: 768px) {
        width: 100%;
      }
      .label {
        color: $btn-primary;
        font-size: 0.9rem;
        font-family: "Roboto";
      }
      .title {
        color: $text-primary;
        font-size: 3.63rem;
        font-weight: 500;
        margin: 0;
        font-family: "Poppins";
        width: 80%;
        @media (max-width: 768px) {
          font-size: 2.3rem;
          width: 100%;
        }
      }
    }
    .container-description {
      width: calc(100% / 2 - 10px);
      @media (max-width: 768px) {
        width: 100%;
        order: 3;
      }

      .description {
        color: $text-secondary;
        font-size: 1rem;
        font-weight: 400;
        font-family: "Roboto";
        margin-top: 39px;
        margin-bottom: 25px;
        line-height: 1.3rem;
        @media (max-width: 768px) {
          margin-top: 25px;
        }
      }
      .btn-about {
        font-family: "Roboto";
        font-weight: 500;
        background-color: $btn-primary;
        border: 1px solid $btn-primary;
        border-radius: 40px;
        padding: 9px 25px;
        cursor: pointer;
        color: $btn-secondary;
        transition: 0.5s ease-in-out;
        font-size: 0.9rem;

        &:hover {
          background-color: transparent;
          transition: 0.5s ease-in-out;
          color: $btn-primary;
        }
      }
    }
    .container-img {
      width: 100%;
      display: flex;
      justify-content: center;

      img {
        width: 100%;
        border-radius: 25px;
        height: 700px;
        object-fit: cover;
        @media (max-width: 768px) {
          height: 380px;
        }
      }
    }
  }
}
