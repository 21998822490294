.privacy {
  display: flex;
  justify-content: center;
  .privacy-line {
    width: 80%;
    @media (max-width: 768px) {
      width: 90%;
    }
  }
}
