@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
$btn-primary: #57f785;
$btn-secondary: #002b0e;
$primary-text: #fffaf6;
$hover-text: #9dd4db;
.navbar {
  background-color: #36301f;
  height: 91px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: relative;
  box-shadow: 0 0 10px 2px #504e4f;
  top: 0;
  z-index: 999;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    .navbar-logo {
      color: $primary-text;
      justify-self: start;
      cursor: pointer;
      display: flex;
      align-items: center;
      text-decoration: none;

      img {
        width: 154px;
        height: 65px;
      }
    }
  }
  .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    padding: 0;
  }
  .nav-item {
    margin: 6px;
    padding: 9px;
  }

  .nav-links {
    color: $primary-text;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    position: relative;
    &:hover {
      color: $hover-text;
    }
  }

  .menu-icon {
    display: none;
  }
  .btn-navbar {
    display: flex;
    align-items: center;
    font-family: "Roboto";
    font-weight: 500;
    background-color: $btn-primary;
    border: 1px solid $btn-primary;
    border-radius: 40px;
    padding: 9px 25px;
    cursor: pointer;
    color: $btn-secondary;
    transition: 0.5s ease-in-out;
    font-size: 0.9rem;
    fill: $btn-secondary;
    &:hover {
      background-color: transparent;
      transition: 0.5s ease-in-out;
      color: $btn-primary;
      fill: $btn-primary;
    }
    .btn-icon {
      width: 16px;
      height: 16px;
      margin-right: 6px;
      padding: 0;
    }
  }

  @media only screen and (max-width: 1024px) {
    .navbar-container {
      align-items: center;
      width: 90%;
    }
    .nav-menu {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-top: 20px;
      border: none;
      border-radius: 0px;
      background-color: rgba(0, 0, 0, 0.9); /* Optional background for menu */
      position: absolute;
      width: 100%;
      top: 100%; /* Position menu below navbar on small screens */
      left: 0;
      margin: 0;
      padding: 0;
      transition: all 0.3s ease-in-out;
      opacity: 0; /* Hide menu initially */
      visibility: hidden; /* Hide menu initially */
    }
    .nav-menu.active {
      opacity: 1; /* Show menu when active */
      visibility: visible; /* Show menu when active */
    }
    .menu-icon {
      display: flex; /* Show menu icon on small screens */
      color: $primary-text;
    }
    .btn-navbar {
      display: none;
    }

    .nav-item {
      margin: 0.42rem 2rem;
    }
    .btn-navbar {
      margin: 0;
    }
  }
}
